import { coachApi, reserveerApi } from "@/lib/backend";
import type { IomodelsCoachappLidMetPrioInfo } from "@/lib/backend/club.api";
import type { AanwezigheidFitnessAfspraakExtern, Afspraak } from "@/lib/backend/coach.api";
import type { IomodelsFitnessAfspraakExtern } from "@/lib/backend/reserveer.api";
import { logger } from "@/logger";
import type { CoachService } from "./services/coach";

type SuccessSubmitResponse = "new_slot" | "updated" | "no_changes";

export class UpdateManager {
	coachService: CoachService;

	hasSlotChanged = false;
	hasGoalOrSchemaChanged = false;
	hasCoachChanged = false;

	previousLocation?: LocationSlug;

	previousAppointment?: IomodelsFitnessAfspraakExtern;
	previousFitnessAfspraak?: IomodelsFitnessAfspraakExtern;
	previousSlot?: Afspraak;
	previousCoachId?: number | null | undefined;

	currentAppointment?: IomodelsFitnessAfspraakExtern;
	currentFitnessAfspraak?: IomodelsFitnessAfspraakExtern;
	currentSlot?: Afspraak;
	currentCoachId?: number;

	gebruiker?: IomodelsCoachappLidMetPrioInfo;

	constructor(
		coachService: CoachService,

		previousAfspraak: IomodelsFitnessAfspraakExtern,
		previousAppointment: IomodelsFitnessAfspraakExtern,
		previousCoachId: number | null | undefined,
		currentSlot: Afspraak,
		gebruiker: IomodelsCoachappLidMetPrioInfo,
	) {
		this.coachService = coachService;

		this.previousFitnessAfspraak = previousAfspraak;
		this.previousAppointment = previousAppointment;
		this.previousSlot = currentSlot;
		this.previousCoachId = previousCoachId;

		this.currentAppointment = { ...previousAppointment };
		this.currentFitnessAfspraak = { ...previousAfspraak };

		this.currentSlot = { ...currentSlot };

		this.gebruiker = gebruiker;
	}

	// Getters
	getCurrentAppointment() {
		return this.currentAppointment;
	}

	getCurrentFitnessAfspraak() {
		return this.currentFitnessAfspraak;
	}

	// Setters
	setCurrentAppointment(appointment: IomodelsFitnessAfspraakExtern) {
		this.currentAppointment = appointment;
	}

	setCurrentFitnessAfspraak(afspraak: IomodelsFitnessAfspraakExtern) {
		this.hasGoalOrSchemaChanged = true;
		this.currentFitnessAfspraak = afspraak;
	}

	setSlot(slot: Afspraak) {
		logger.log({
			slot1: slot.afspraakId,
			slot2: this.currentSlot?.afspraakId,
		});
		if (slot.afspraakId !== this.currentSlot?.afspraakId) {
			this.hasSlotChanged = true;
			this.currentSlot = slot;
		}
	}

	setCoachId(coachId: number) {
		if (coachId !== this.currentCoachId) {
			this.hasCoachChanged = true;
			this.currentCoachId = coachId;
		}
	}

	// Methods.
	async createNewAppointment() {
		if (!this.gebruiker || !this.currentSlot) {
			throw new Error("No gebruiker or slot");
		}

		const result = await this.coachService.makeAppointment(Number(this.currentSlot.afspraakId), {
			...this.currentFitnessAfspraak?.aanwezigheid,
			trainerId: this.currentCoachId,
		});

		return result.data;
	}

	async updateAppointment(): Promise<IomodelsFitnessAfspraakExtern> {
		if (!this.gebruiker || !this.currentSlot) {
			throw new Error("No gebruiker or slot");
		}

		if (!this.currentFitnessAfspraak) {
			throw new Error("No current fitness afspraak");
		}

		const result = await this.coachService.updateAppointment(this.currentFitnessAfspraak.id, {
			...this.currentFitnessAfspraak.aanwezigheid,
			trainerId: this.currentCoachId,
		});

		logger.log("result", result.data);

		return result.data;
	}

	async updateFitnessAfpraak(afspraak: IomodelsFitnessAfspraakExtern) {
		const naam = `${this.gebruiker?.voornaam} ${this.gebruiker?.achternaam}`;
		const lidId = this.gebruiker?.id;

		if (!this.currentFitnessAfspraak) {
			throw new Error("Geen fitness afspraak om te updaten.");
		}
		if (!naam || !lidId) {
			throw new Error("Geen naam of lidId");
		}

		await reserveerApi.fitnessafspraken.wijzigAfspraak({
			...afspraak,
			trainerId: this.currentCoachId,
			aanwezigheid: {
				...afspraak.aanwezigheid,
				lidNaam: afspraak.aanwezigheid?.lidNaam ?? naam,
				lidId: afspraak.aanwezigheid?.lidId ?? lidId,
				status: afspraak.aanwezigheid?.status ?? "aangemeld",
				meting: afspraak.aanwezigheid?.meting ?? false,
				trainingsSchema: afspraak.aanwezigheid?.trainingsSchema ?? false,
				doelOpstellen: afspraak.aanwezigheid?.doelOpstellen ?? false,
				personalTraining: afspraak.aanwezigheid?.personalTraining ?? false,
				kickOff: afspraak.aanwezigheid?.kickOff ?? false,
			},
		});
	}
	async updateAll() {
		const fitnessAfspraakExtern = await this.updateAppointment();
		if (fitnessAfspraakExtern) {
			await this.updateFitnessAfpraak(fitnessAfspraakExtern);
		}
		return;
	}

	async createNew() {
		const fitnessAfspraakExtern = await this.createNewAppointment();
		if (fitnessAfspraakExtern) {
			await this.updateFitnessAfpraak(fitnessAfspraakExtern);
		}
		return;
	}

	async cancelPreviousAppointment() {
		if (!this.previousFitnessAfspraak) {
			throw new Error("Geen vorige afspraak om te annuleren.");
		}
		await coachApi.api.fitnessafsprakenAanwezigheidCreate(this.previousFitnessAfspraak.id, {
			...this.previousFitnessAfspraak?.aanwezigheid,
			lidId: this.previousFitnessAfspraak.aanwezigheid?.lidId ?? -1,
			lidNaam: this.previousFitnessAfspraak.aanwezigheid?.lidNaam ?? "",
			meting: this.previousFitnessAfspraak.aanwezigheid?.meting ?? false,
			trainingsSchema: this.previousFitnessAfspraak.aanwezigheid?.trainingsSchema ?? false,
			doelOpstellen: this.previousFitnessAfspraak.aanwezigheid?.doelOpstellen ?? false,
			personalTraining: this.previousFitnessAfspraak.aanwezigheid?.personalTraining ?? false,
			kickOff: this.previousFitnessAfspraak.aanwezigheid?.kickOff ?? false,
			isTemporaryMember:
				(this.previousFitnessAfspraak.aanwezigheid as AanwezigheidFitnessAfspraakExtern)
					?.isTemporaryMember ?? false,
			opmerkingen: this.previousFitnessAfspraak.aanwezigheid?.opmerkingen ?? "",
			status: "afgemeld",
		});
	}

	async submit(): Promise<SuccessSubmitResponse> {
		if (!this.hasSlotChanged && !this.hasGoalOrSchemaChanged && !this.hasCoachChanged) {
			logger.log("Nothing changed");
			return "updated";
		}

		if (!this.hasSlotChanged) {
			if (this.hasGoalOrSchemaChanged) {
				await this.updateAll();
				return "updated";
			}

			if (this.hasCoachChanged) {
				if (this.previousFitnessAfspraak) {
					await this.updateFitnessAfpraak(this.previousFitnessAfspraak);
					return "updated";
				}
			}
		}

		if (this.hasSlotChanged) {
			await this.createNew();
			await this.cancelPreviousAppointment();
			return "new_slot";
		}

		return "no_changes";
	}
}
