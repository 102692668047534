




































































































































































import { defineComponent } from "@vue/composition-api";
import { isBefore } from "date-fns";
import { DateTime } from "klokwerk";
import { mapActions as mapPiniaActions, mapState as mapPiniaState, mapStores } from "pinia";
import { mapActions } from "vuex";
import { UpdateManager } from "@/UpdateAfspraakHelper";
import { coachApi, reserveerApi } from "@/lib/backend";
import type { Afspraak, AfsprakenMaand } from "@/lib/backend/coach.api";
import type { IomodelsFitnessAfspraakExtern, IomodelsVestiging } from "@/lib/backend/reserveer.api";
import { APPOINTMENT_MAP } from "@/lib/constants/options";
import { logger } from "@/logger";
import { useCoachStore } from "@/pinia/coach";
import { useGebruikerStore } from "@/pinia/gebruiker";
import { useLocationStore } from "@/pinia/location";
import { usePopupStore } from "@/pinia/popup";
import { CoachService } from "@/services/coach";
import {
	getDateFromDatum,
	getDateFromDatumAndTijd,
	getDayMonthYear,
	getMonthYear,
} from "@/utils/date";
import { removeDuplicates } from "@/utils/removeDuplicates";

const coachService = new CoachService();

type Toggle = {
	slug: string;
	text: string;
	enabled: boolean;
};

type Data = {
	loading: boolean;
	vestigingSlug?: LocationSlug;
	coach: number | null | undefined;
	slotMessage?: string;
	toggleMessage?: string;
	toggles: Toggle[];
	disabledDates: {
		to: Date;
	};
	comments?: string | undefined;
	date: Date;
	appointments: AfsprakenMaand;
	selectedSlot?: Afspraak;

	previousAfspraak?: IomodelsFitnessAfspraakExtern;
	previousLocation?: LocationSlug;

	updateManager?: UpdateManager;
};

export default defineComponent({
	name: "PfgGebruikerAfspraakInplannen",
	data(): Data {
		return {
			updateManager: undefined,
			loading: false,
			vestigingSlug: undefined,
			coach: undefined,
			slotMessage: undefined,
			toggleMessage: undefined,
			toggles: Object.entries(APPOINTMENT_MAP).map(([slug, appointment]) => ({
				slug,
				...appointment,
				enabled: false,
			})),
			disabledDates: {
				to: new DateTime().setDate((current) => current.date - 1).native,
			},
			comments: undefined,
			date: new Date(),
			appointments: {},
			selectedSlot: undefined,

			previousAfspraak: undefined,
			previousLocation: undefined,
		};
	},
	computed: {
		...mapStores(usePopupStore, useGebruikerStore),
		...mapPiniaState(useCoachStore, {
			coachesByLocation: "coachesByLocationSlugSortedByName",
			coachById: "coachById",
		}),
		...mapPiniaState(useLocationStore, {
			location: "location",
			locationSlug: "locationSlug",
			locations: "locationsSortedByName",
			locationById: "locationById",
		}),
		...mapPiniaState(useGebruikerStore, {
			id: "id",
			gebruiker: "basicInfo",
		}),
		showKickOff(): boolean {
			return !(this.gebruikerStore.lidInfo?.onboardingAfgerond ?? true);
		},
		locationName(): string {
			return this.locations.find((location) => location.slug === this.vestigingSlug)?.naam ?? "";
		},
		slots(): AfsprakenMaand[string] {
			if (!this.appointments) return [];
			const slots = this.appointments[getDayMonthYear(this.date, "short")] ?? [];
			return removeDuplicates(slots, "slot");
		},
		hasActiveSlots(): boolean {
			if (!this.slots) return false;

			return this.slots.some((slot) => !slot.busy && slot.status !== "afgelopen");
		},
	},
	watch: {
		vestiging: {
			handler(newVal, oldVal) {
				if (newVal !== oldVal) {
					this.appointments = {};
					this.getAfspraken();
				}
			},
		},
		toggles: {
			handler(newVal: Toggle[]) {
				if (newVal.some((toggle: Toggle): boolean => toggle.enabled)) this.toggleMessage = "";
			},
			deep: true,
		},
		$route: "check",
	},
	async created() {
		await this.initLocation();
		await this.initCoach();
		await this.getAfspraken();

		// Fetch exiting data.
		const userId = Number(this.$route.params.id);
		const afspraakId = Number(this.$route.params.afspraakId);

		// Simpel fetch on id does not exist to fetch all from user
		if (!userId || !afspraakId) {
			this.$notify({
				title: "Fout",
				text: "Geen gebruiker of afspraak gevonden",
				type: "error",
			});
			this.$router.push({
				name: "Gebruiker",
				params: { id: String(userId) },
			});
		}

		const range = {
			startDatum: new DateTime().setDate((current) => current.date - 1).toISOString(),
			eindDatum: new DateTime().setDate((current) => current.date + 365).toISOString(),
		};

		const lidAppointments = (await coachService.getLidAppointmentsBooked(userId, range)).data;

		const appointment = lidAppointments?.find((appointment) => appointment.id === afspraakId);

		if (!appointment) return;
		const fitnessAfspraak = (await reserveerApi.fitnessafspraken.getAfspraak(appointment.id)).data;

		if (!fitnessAfspraak) return;

		const location = this.locationById(fitnessAfspraak.vestigingId);

		if (!location) {
			this.$notify({
				title: "Fout",
				text: "Geen coach gevonden",
				type: "error",
			});
			this.$router.push({
				name: "Afspraken",
			});
			return;
		}

		// Find slots.
		this.date = new Date(fitnessAfspraak.datum);
		const key = getDayMonthYear(this.date, "short");
		const _slots = this.appointments[key]?.map((slot) => ({
			...slot,
			id: slot.afspraakId,
		}));
		const foundSlot = _slots?.find((slot) => slot.id === fitnessAfspraak.id);

		// Load afspraak goal.
		const fitnessAfspraakExternAanwezigheid = fitnessAfspraak.aanwezigheid;
		this.toggles = this.toggles.map((toggle) => {
			let enabled = false;
			switch (toggle.slug) {
				case "meting":
					enabled = fitnessAfspraakExternAanwezigheid?.meting ?? false;
					break;
				case "trainingsSchema":
					enabled = fitnessAfspraakExternAanwezigheid?.trainingsSchema ?? false;
					break;
				case "doelOpstellen":
					enabled = fitnessAfspraakExternAanwezigheid?.doelOpstellen ?? false;
					break;
				case "personalTraining":
					enabled = fitnessAfspraakExternAanwezigheid?.personalTraining ?? false;
					break;
				case "kickOff":
					enabled = fitnessAfspraakExternAanwezigheid?.kickOff ?? false;
					break;
			}

			return {
				...toggle,
				enabled,
			};
		});

		if (!foundSlot) {
			logger.log("Slot not found");
			return;
		}

		await this.check();

		// Set all existing values.
		this.selectedSlot = foundSlot;
		this.vestigingSlug = location.slug;
		this.onChangedVestiging();
		this.coach = fitnessAfspraak.trainerId;
		this.previousAfspraak = fitnessAfspraak;
		this.comments = fitnessAfspraak.aanwezigheid?.opmerkingen || undefined;

		if (!this.gebruikerStore.basicInfo) {
			throw new Error("No member found");
		}

		// Load managers.
		this.updateManager = new UpdateManager(
			coachService,
			this.previousAfspraak,
			fitnessAfspraak,
			this.coach,
			foundSlot,
			this.gebruikerStore.basicInfo,
		);
	},
	methods: {
		...mapPiniaActions(useCoachStore, {
			initCoach: "init",
		}),
		...mapPiniaActions(useLocationStore, {
			initLocation: "init",
		}),
		...mapPiniaActions(useGebruikerStore, ["set", "getBasicInfo"]),
		...mapPiniaActions(useGebruikerStore, ["set", "getLidInfo"]),
		...mapActions("modal", ["openModal"]),
		shouldShowToggle(toggle: Toggle): boolean {
			if (toggle.slug !== "kickOff") return true;

			return this.showKickOff;
		},
		isPreviousSlot(slot: Afspraak): boolean {
			return this.previousAfspraak?.id === slot.afspraakId;
		},
		isSelectedSlot(slot: Afspraak): boolean {
			return this.selectedSlot?.afspraakId === slot.afspraakId;
		},
		shouldShowBusy(slot: Afspraak): boolean {
			return slot.busy && !this.isPreviousSlot(slot);
		},
		async check() {
			this.loading = true;

			const { id } = this.$route.params;

			const numberId = Number(id);

			if (isNaN(numberId)) return;

			const currentId = this.id;

			if (currentId !== numberId) this.set(numberId);

			await this.getRequiredInfo();

			this.loading = false;
		},
		async getRequiredInfo() {
			await this.getBasicInfo();
			await this.getLidInfo();
			await this.getAfspraken();
		},
		reset() {
			// @ts-expect-error Apply not correctly typed
			Object.assign(this.$data, this.$options.data?.apply(this));
		},
		async changedMonth(date: Date | { timestamp: string }) {
			// The datepicker module either emits a date when a new month is selected or an object with a timestamp.
			this.date = date instanceof Date ? date : new Date(date.timestamp);

			await this.getAfspraken();
		},
		selectSlot(slot: Afspraak) {
			logger.log({ slot });
			if (slot.busy && !this.isPreviousSlot(slot)) return;
			logger.log("not busy");

			this.selectedSlot = slot;
			this.slotMessage = undefined;
		},
		async onChangedVestiging() {
			await this.getAfspraken();
		},
		async getAfspraken() {
			try {
				const response = await coachApi.api.appointmentsMonthDetail(
					this.vestigingSlug ?? "all",
					getMonthYear(this.date),
				);

				switch (response.status) {
					case 200: {
						this.appointments = response.data;
						break;
					}

					default: {
						throw response;
					}
				}
			} catch (error) {
				logger.error(error);
			}
		},
		savePopup() {
			if (!this.selectedSlot?.afspraakId) this.slotMessage = "Selecteer een afspraak tijdstip";
			if (this.toggles.every((toggle) => !toggle.enabled))
				this.toggleMessage = "Selecteer minstens één optie";

			if (this.slotMessage || this.toggleMessage) return false;

			if (!this.selectedSlot) return false;

			const gebruiker = this.gebruikerStore.basicInfo;

			if (!gebruiker) return false;

			const body = `Wil je de afspraak bijwerken:
                <p>
                    <b>${this.selectedSlot.datum}</b> om <b>${this.selectedSlot.slot}</b> in
                    <b>${
											this.locations.find(
												(location: IomodelsVestiging): boolean =>
													location.slug === this.vestigingSlug,
											)?.naam
										}</b>
                    voor
                    <b>${gebruiker.voornaam} ${gebruiker.achternaam}</b>
                </p>

                Tijdens de afspraak worden de volgende punten behandeld:
                <ul>
                    ${this.toggles
											.filter((toggle) => toggle.enabled)
											.map((toggle) => `<li><b>${toggle.text}</b></li>`)
											.join("")}
                </ul>
                Met de volgende opmerking:
                <p>
                    <b>${this.comments ? this.comments : "Geen opmerkingen."}</b>
                </p>
            `;

			return this.popupStore.open({
				title: "Let op!",
				body,
				buttons: {
					cancel: "Annuleren",
					confirm: "Afspraak bevestingen",
				},
				callback: async () => {
					await this.save();
				},
			});
		},
		async save() {
			const currentAfspraak = this.updateManager?.getCurrentFitnessAfspraak();

			if (!currentAfspraak || !this.selectedSlot) {
				this.$notify({
					title: "Er is iets misgegaan",
					text: "Geen gebruiker of afspraak gevonden",
					type: "error",
				});
				return;
			}

			try {
				// Set new data.
				this.updateManager?.setSlot(this.selectedSlot);
				this.updateManager?.setCurrentFitnessAfspraak({
					...currentAfspraak,
					aanwezigheid: {
						...currentAfspraak.aanwezigheid,
						meting: currentAfspraak.aanwezigheid?.meting ?? false,
						trainingsSchema: currentAfspraak.aanwezigheid?.trainingsSchema ?? false,
						doelOpstellen: currentAfspraak.aanwezigheid?.doelOpstellen ?? false,
						personalTraining: currentAfspraak.aanwezigheid?.personalTraining ?? false,
						kickOff: currentAfspraak.aanwezigheid?.kickOff ?? false,
						...this.toggles.reduce<Record<string, boolean>>(
							(obj, toggle) => ({
								...obj,
								[toggle.slug]: toggle.enabled,
							}),
							{},
						),
						lidId: currentAfspraak.aanwezigheid?.lidId ?? -1,
						opmerkingen: this.comments,
						status: "aangemeld",
					},
				});
				this.updateManager?.setCoachId(this.coach ?? -1);

				// Submit.
				await this.updateManager?.submit();
				this.$router.push({
					name: "Afspraken",
				});
			} catch (error) {
				this.$notify({
					title: "Er is iets misgegaan",
					text: "Geen gebruiker of afspraak gevonden",
					type: "error",
				});
				logger.log(error);
				return;
			}
		},
		getDateFromDatum,
		getDateFromDatumAndTijd,
		isBefore,
	},
});
